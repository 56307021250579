$(document).ready(function() {
  // Add smooth scrolling to all links
  // $("a").on("click", function(event) {
  //   // Make sure this.hash has a value before overriding default behavior
  //   if (this.hash !== "") {
  //     // Prevent default anchor click behavior
  //     event.preventDefault();

  //     // Store hash
  //     var hash = this.hash;

  //     // Using jQuery's animate() method to add smooth page scroll
  //     // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
  //     $("html, body").animate(
  //       {
  //         scrollTop: $(hash).offset().top
  //       },
  //       800,
  //       function() {
  //         // Add hash (#) to URL when done scrolling (default click behavior)
  //         window.location.hash = hash;
  //       }
  //     );
  //   } // End if
  // });

    // Smooth scrolling using jQuery easing
    $('a.js-scroll-trigger[href*="#"]:not([href="#"])').click(function() {
      if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        if (target.length) {
          $('html, body').animate({
            scrollTop: (target.offset().top - 70)
          }, 1000);
          return false;
        }
      }
    });


 // Activate scrollspy to add active class to navbar items on scroll
 $('body').scrollspy({
  target: '#mainNav',
  offset: 100
});

  $(".navbar-nav .nav-link").on("click", function(){
    $(".navbar-nav").find(".active").removeClass("active");
    $(this).addClass("active");
    $('.navbar-collapse').collapse('hide');
 });

  // Collapse Navbar
var navbarCollapse = function() {
  if ($("#mainNav").offset().top > 100) {
    $("#mainNav").addClass("navbar-shrink shadow-sm");
  } else {
    $("#mainNav").removeClass("navbar-shrink shadow-sm");
  }
};
// Collapse now if page is not at top
navbarCollapse();
// Collapse the navbar when page is scrolled
$(window).scroll(navbarCollapse);





var popup_btn = $('.popup-btn');
  popup_btn.magnificPopup({
  type : 'image',
  titleSrc: 'title',
  gallery : {
    enabled : true
  }
});


$('.portfolio-menu ul li').click(function(){
  $('.portfolio-menu ul li').removeClass('active');
  $(this).addClass('active');
  
  var selector = $(this).attr('data-filter');
  $('.portfolio-item').isotope({
    filter:selector
  });
  return  false;
});


});

// Portfolio
$('.portfolio-item').isotope({
  itemSelector: '.col',
  layoutMode: 'fitRows'
});


// Scroll to top button appear
$(document).scroll(function() {
  var scrollDistance = $(this).scrollTop();
  if (scrollDistance > 100) {
    $('.scroll-to-top').fadeIn();
  } else {
    $('.scroll-to-top').fadeOut();
  }
});



